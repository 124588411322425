<template>
  <v-dialog
    v-model="duplicationModal"
    max-width="650px"
    @click:outside="closeDuplicationModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Duplicate Campaign</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.name"
                  label="Campaign name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-slider
                  v-model="campaign.campaignSize"
                  thumb-label="always"
                  min="750"
                  max="5000"
                  step="50"
                  ticks
                  label="Number of prospects*"
                ></v-slider>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="campaign.startDate"
                      label="Start date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="campaign.startDate"
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="campaign.endDate"
                      label="End date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="campaign.endDate"
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="campaign.timeZone"
                  :items="timeZones"
                  label="Timezone*"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="startHourMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="campaign.dailyStartTime"
                      label="Start sending at*"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-if="startHourMenu"
                    v-model="campaign.dailyStartTime"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="endHourMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="campaign.dailyEndTime"
                      label="Stop sending at*"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-if="endHourMenu"
                    v-model="campaign.dailyEndTime"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-btn-toggle
                  v-model="campaign.sendDays"
                  dense
                  background-color="primary"
                  multiple
                >
                  <v-btn> M </v-btn>

                  <v-btn> T </v-btn>

                  <v-btn> W </v-btn>

                  <v-btn> T </v-btn>

                  <v-btn> F </v-btn>

                  <v-btn> S </v-btn>

                  <v-btn> S </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="campaign.senderAccountId"
                  :items="accounts"
                  :item-text="
                    (account) => `${account.fullName} (${account.user})`
                  "
                  item-value="_id"
                  label="Sender Account*"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="duplicateCampaign()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment-timezone";

export default {
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startHourMenu: false,
      endHourMenu: false,
      timeZones: [],
      time: null,
      accounts: [],
    };
  },
  async created() {
    this.timeZones = moment.tz.names() || [];

    try {
      const response = await this.$http.get(
        `/domains?_filter=${this.selectedClient}`
      );
      this.accounts = await response.data.flatMap((domain) => domain.accounts);
    } catch (error) {
      console.log(error);
    }
  },
  props: {
    duplicationModal: Boolean,
    campaign: Object,
    selectedClient: undefined,
  },
  methods: {
    closeDuplicationModal() {
      this.$emit("closeDuplicationModal");
    },
    async duplicateCampaign() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post("/campaigns", {
          name: this.campaign.name,
          clientId: this.selectedClient,
          campaignSize: this.campaign.campaignSize,
          startDate: this.campaign.startDate,
          endDate: this.campaign.endDate,
          dailyStartTime: this.campaign.dailyStartTime,
          dailyEndTime: this.campaign.dailyEndTime,
          timeZone: this.campaign.timeZone,
          senderAccountId: this.campaign.senderAccountId,
          sendDays: this.campaign.sendDays,
        });

        this.$emit("campaignDuplicated", response.data);
      } catch (err) {
        this.$emit("failedDuplicatingCampaign", err);
      }
    },
  },
};
</script>
