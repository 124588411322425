<template>
  <div class="campaigns">
    <create-campaign
      :create-modal="createModal"
      :selected-client="selectedClient"
      @closeCreateModal="closeCreateModal"
      @failedCreatingCampaign="failedCreatingCampaign"
      @campaignSaved="campaignSaved"
    ></create-campaign>
    <duplicate-campaign
      :duplication-modal="duplicationModal"
      :selected-client="selectedClient"
      :campaign="campaignTemplate"
      @closeDuplicationModal="closeDuplicationModal"
      @failedDuplicatingCampaign="failedDuplicatingCampaign"
      @campaignDuplicated="campaignDuplicated"
    ></duplicate-campaign>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectedCampaign && selectedCampaign.length"
        class="mx-2"
        fab
        dark
        small
        color="red darken-2"
        @click="deleteCampaign()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="selectedCampaign && selectedCampaign.length"
        class="mx-2"
        fab
        dark
        small
        color="purple"
        @click="duplicationModal = true"
      >
        <v-icon>mdi-content-duplicate</v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo"
        @click="createModal = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-data-table
          v-model="selectedCampaign"
          class="click-table"
          :headers="headers"
          :items="campaigns"
          item-key="_id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="dataLoading"
          @click:row="handleRowClick"
          show-select
          single-select
        >
          <template v-slot:item.createdAt="{ value }">
            <time :title="value" :datetime="value">{{
              formatDate(value)
            }}</time>
          </template>
          <template v-slot:item.startDate="{ value }">
            <time :title="value" :datetime="value">{{
              formatDate(value)
            }}</time>
          </template>
          <template v-slot:item.endDate="{ value }">
            <time :title="value" :datetime="value">{{
              formatDate(value)
            }}</time>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import CreateCampaign from "@/components/CreateCampaign.vue";
import DuplicateCampaign from "@/components/DuplicateCampaign.vue";
import { store } from "@/state/store.js";

export default {
  components: {
    CreateCampaign,
    DuplicateCampaign,
  },
  data() {
    return {
      campaigns: [],
      senders: [],
      selectedCampaign: undefined,
      sortBy: "name",
      sortDesc: false,
      dataLoading: true,
      createModal: false,
      duplicationModal: false,
      store,
    };
  },
  created() {
    this.loadCampaigns();
  },
  computed: {
    headers() {
      return [
        {
          text: "Campaign Name",
          value: "name",
        },
        {
          text: "Start Date",
          value: "startDate",
        },
        {
          text: "End Date",
          value: "endDate",
        },
        {
          text: "Campaign Size",
          value: "campaignSize",
        },
        {
          text: "Status",
          value: "campaignStatus",
        },
        { text: "Date Created", value: "createdAt" },
      ];
    },
    selectedClient() {
      return this.store.selectedClient;
    },
    campaignTemplate() {
      return this.selectedCampaign && this.selectedCampaign.length
        ? {
            name: `${this.selectedCampaign[0].name} - Copy`,
            campaignSize: this.selectedCampaign[0].campaignSize,
            startDate: moment(this.selectedCampaign[0].startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(this.selectedCampaign[0].endDate).format(
              "YYYY-MM-DD"
            ),
            timeZone: this.selectedCampaign[0].timeZone,
            dailyStartTime: this.selectedCampaign[0].dailyStartTime,
            dailyEndTime: this.selectedCampaign[0].dailyEndTime,
            sendDays: this.selectedCampaign[0].sendDays,
            senderAccountId: this.selectedCampaign[0].senderAccountId,
          }
        : {};
    },
  },
  watch: {
    selectedClient() {
      this.loadCampaigns();
    },
  },
  methods: {
    formatDate(dt) {
      if (!dt) {
        return "-";
      }
      return moment(dt).format("MM/DD/YYYY hh:mm");
    },
    async loadCampaigns() {
      try {
        const campaignsResp = await this.$http.get(
          `/campaigns?_filter=${this.selectedClient}`
        );

        this.campaigns = campaignsResp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    closeCreateModal() {
      this.createModal = false;
    },
    failedCreatingCampaign(error) {
      console.log(error);
      this.createModal = false;
    },
    campaignSaved() {
      this.loadCampaigns();
      this.createModal = false;
    },
    closeDuplicationModal() {
      this.duplicationModal = false;
    },
    failedDuplicatingCampaign(error) {
      console.log(error);
      this.duplicationModal = false;
    },
    campaignDuplicated() {
      this.loadCampaigns();
      this.duplicationModal = false;
    },
    handleRowClick(row) {
      this.$router.push({ name: "campaign", params: { campaignId: row._id } });
    },
    async deleteCampaign() {
      const campaign = this.selectedCampaign[0];
      try {
        await this.$http.delete(`/campaigns/${campaign._id}`);
        this.selectedCampaign = undefined;
        this.loadCampaigns();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
