var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"650px"},on:{"click:outside":function($event){return _vm.closeDuplicationModal()}},model:{value:(_vm.duplicationModal),callback:function ($$v) {_vm.duplicationModal=$$v},expression:"duplicationModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Duplicate Campaign")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mx-2",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Campaign name*","required":""},model:{value:(_vm.campaign.name),callback:function ($$v) {_vm.$set(_vm.campaign, "name", $$v)},expression:"campaign.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-slider',{attrs:{"thumb-label":"always","min":"750","max":"5000","step":"50","ticks":"","label":"Number of prospects*"},model:{value:(_vm.campaign.campaignSize),callback:function ($$v) {_vm.$set(_vm.campaign, "campaignSize", $$v)},expression:"campaign.campaignSize"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start date*","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.campaign.startDate),callback:function ($$v) {_vm.$set(_vm.campaign, "startDate", $$v)},expression:"campaign.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.campaign.startDate),callback:function ($$v) {_vm.$set(_vm.campaign, "startDate", $$v)},expression:"campaign.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.campaign.endDate),callback:function ($$v) {_vm.$set(_vm.campaign, "endDate", $$v)},expression:"campaign.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.campaign.endDate),callback:function ($$v) {_vm.$set(_vm.campaign, "endDate", $$v)},expression:"campaign.endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.timeZones,"label":"Timezone*"},model:{value:(_vm.campaign.timeZone),callback:function ($$v) {_vm.$set(_vm.campaign, "timeZone", $$v)},expression:"campaign.timeZone"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start sending at*","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.campaign.dailyStartTime),callback:function ($$v) {_vm.$set(_vm.campaign, "dailyStartTime", $$v)},expression:"campaign.dailyStartTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startHourMenu),callback:function ($$v) {_vm.startHourMenu=$$v},expression:"startHourMenu"}},[(_vm.startHourMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.campaign.dailyStartTime),callback:function ($$v) {_vm.$set(_vm.campaign, "dailyStartTime", $$v)},expression:"campaign.dailyStartTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Stop sending at*","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.campaign.dailyEndTime),callback:function ($$v) {_vm.$set(_vm.campaign, "dailyEndTime", $$v)},expression:"campaign.dailyEndTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endHourMenu),callback:function ($$v) {_vm.endHourMenu=$$v},expression:"endHourMenu"}},[(_vm.endHourMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.campaign.dailyEndTime),callback:function ($$v) {_vm.$set(_vm.campaign, "dailyEndTime", $$v)},expression:"campaign.dailyEndTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"dense":"","background-color":"primary","multiple":""},model:{value:(_vm.campaign.sendDays),callback:function ($$v) {_vm.$set(_vm.campaign, "sendDays", $$v)},expression:"campaign.sendDays"}},[_c('v-btn',[_vm._v(" M ")]),_c('v-btn',[_vm._v(" T ")]),_c('v-btn',[_vm._v(" W ")]),_c('v-btn',[_vm._v(" T ")]),_c('v-btn',[_vm._v(" F ")]),_c('v-btn',[_vm._v(" S ")]),_c('v-btn',[_vm._v(" S ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.accounts,"item-text":function (account) { return ((account.fullName) + " (" + (account.user) + ")"); },"item-value":"_id","label":"Sender Account*"},model:{value:(_vm.campaign.senderAccountId),callback:function ($$v) {_vm.$set(_vm.campaign, "senderAccountId", $$v)},expression:"campaign.senderAccountId"}})],1)],1)],1)],1),_c('small',[_vm._v("* indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.duplicateCampaign()}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }